import React, { useState } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { PlayFill, StopFill, CardText, ListUl, ForwardFill, ReplyFill, Translate, Clipboard2Fill, Clipboard2CheckFill, Clipboard2MinusFill, XLg, Windows } from 'react-bootstrap-icons';
//import "./dyslexia.js"


const serverUrl = window.location.protocol + "//" + window.location.hostname + (window.location.hostname == "localhost" ? ":3000" : "/api/v1");

async function callServer(request: object, path: string) {
  console.log("Calling: ", serverUrl + path);
  console.log("Input data: ", request);
  try {
    const response = await axios.post(serverUrl + path, request);
    console.log("Output data: ", response);
    return { response: response };
  } catch (error) {
    console.error(error);
    return { error: error };
  }
};

async function getEmailSuggestion(subject: string, body: string) {
  const response = await callServer({ subject: subject, body: body }, "/email/suggestion");
  if (response.response)
    return response.response.data;
  else
    return { "subject": "", "body": response.error };
};

const tooltipSuggestion = (props: object) => (
  <Tooltip id="button-tooltip" {...props}>
    Föreslå förbättringar
  </Tooltip>
);

async function getEmailResponse(subject: string, body: string) {
  const response = await callServer({ subject: subject, body: body }, "/email/response");
  if (response.response)
    return response.response.data;
  else
    return { "subject": "", "body": response.error };
};

const tooltipResponse = (props: object) => (
  <Tooltip id="button-tooltip" {...props}>
    Föreslå mejlsvar
  </Tooltip>
);

async function getEmailSummary(subject: string, body: string) {
  const response = await callServer({ subject: subject, body: body }, "/email/summary");
  if (response.response)
    return response.response.data;
  else
    return { "subject": "", "body": response.error };
};

const tooltipSummary = (props: object) => (
  <Tooltip id="button-tooltip" {...props}>
    Sammanfatta mejlet
  </Tooltip>
);

async function getEmailTranslate(subject: string, body: string) {
  const response = await callServer({ subject: subject, body: body }, "/email/translate" );
  if (response.response)
    return response.response.data;
  else
    return { "subject": "", "body": response.error };
};

const tooltipTranslate = (props: object) => (
  <Tooltip id="button-tooltip" {...props}>
    Översätt mejlet
  </Tooltip>
);

const tooltipClear = (props: object) => (
  <Tooltip id="button-tooltip" {...props}>
    Rensa skärmen
  </Tooltip>
);

const tooltipCopy = (props: object) => (
  <Tooltip id="button-tooltip" {...props}>
    Kopiera texten till urklipp
  </Tooltip>
);

async function getEmailLanguage(subject: string, body: string) {
  const response = await callServer({ subject: subject, body: body }, "/email/language");
  if (response.response)
    return response.response.data.language;
  else
    return undefined;
};

let voices: SpeechSynthesisVoice[];

function loadVoices() {
  if (typeof speechSynthesis === 'undefined') {
    return;
  }
  voices = speechSynthesis.getVoices();
}

loadVoices();
if (typeof speechSynthesis !== 'undefined' && speechSynthesis.onvoiceschanged !== undefined) {
  speechSynthesis.onvoiceschanged = loadVoices;
}

let msg: SpeechSynthesisUtterance | undefined;

async function readAloud(text: string) {
  if (msg) {
    stopReadAloud();
    return;
  }

  const language = await getEmailLanguage("", text);
  console.log("Language: ", language);
  let langCode = undefined;
  if (language === "Swedish") {
    langCode = "sv";
  } else if (language === "English") {
    langCode = "en";
  }
  console.log("Code:     ", langCode);
  msg = new SpeechSynthesisUtterance(text);
  if (langCode) {
    for (let i = 0; voices && i < voices.length; i++) {
      if (voices[i].lang.includes(langCode)) {
        msg.lang = voices[i].lang;
        console.log(voices[i].name);
        break;
      }
    }
  }
  msg.onend = stopReadAloud;
  console.log(msg);
  speechSynthesis.speak(msg);
}

function stopReadAloud() {
  speechSynthesis.cancel();
  msg = undefined;
}

function IconPlayStop(props: { play: boolean }): JSX.Element {
  if (props.play) {
    return <PlayFill />;
  } else {
    return <StopFill />;
  }
}

function IconCopyState(props: { state: number }): JSX.Element {
  switch (props.state) {
    default:
      return <Clipboard2Fill />;
    case 1:
      return <Clipboard2CheckFill />;
    case -1:
      return <Clipboard2MinusFill />;
  }
}

function App() {
  //set object for all input values to be set
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [isPlaying, setPlaying] = useState(false);
  const [copyState, setCopyState] = useState(0);
  const [rowsTextArea, setRowsTextArea] = useState(Math.max(Math.trunc(window.innerHeight / 53) - 6, 1));

  React.useEffect(() => {
    function handleResize() {
      setRowsTextArea(Math.max(Math.trunc(window.innerHeight / 53) - 6, 1))
    }
    window.addEventListener('resize', handleResize)
  })

  async function mailSuggestion() {
    const result = await getEmailSuggestion("", inputText);
    if (result.body && typeof result.body == 'string')
      setOutputText(result.body);
  };

  async function mailResponse() {
    const result = await getEmailResponse("", inputText);
    if (result.body && typeof result.body == 'string')
      setOutputText(result.body);
  };

  async function createSummary() {
    const result = await getEmailSummary("", inputText);
    if (result.body && typeof result.body == 'string')
      setOutputText(result.body);
  };

  async function translateText() {
    const result = await getEmailTranslate("", inputText);
    if (result.body && typeof result.body == 'string')
      setOutputText(result.body);
  };

  const tooltipPlay = (props: object) => (
    <Tooltip id="button-tooltip" {...props}>
      {isPlaying ? "Stoppa uppläsning" : "Läs upp förslaget"}
    </Tooltip>
  );


  function copyToClipboard(text: string) {
    setCopyState(0);
    if (text && text.length) {
      var data = [new ClipboardItem({ "text/plain": new Blob([text], { type: "text/plain" }) })];
      navigator.clipboard.write(data).then(function () {
        console.log("Copied to clipboard successfully!");
        setCopyState(1);
      }, function () {
        console.error("Unable to write to clipboard. :-(");
        setCopyState(-1);
      });
    }
  }

  return (
    <div className="App">
      <header className="App-header">
      Mejlassistent - Envirio Labs
      </header>
      <Form className="Input-form">
        <br />
        <Form.Group className="mb-3" controlId="inputForm">
          <h3>Mejl som du vill ha hjälp med</h3>
          <Form.Control as="textarea"
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            rows={rowsTextArea}
            placeholder="Skriv din text här. Den kan vara på valfritt språk." />
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltipSuggestion}>
            <Button aria-label="Föreslå förbättringar" variant="primary" className="rounded-circle" type="button" onClick={mailSuggestion}> <ForwardFill /> </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltipResponse}>
            <Button aria-label="Föreslå mejlsvar" variant="primary" className="rounded-circle" type="button" onClick={mailResponse}> <ReplyFill /> </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltipSummary}>
            <Button aria-label="Sammanfatta mejlet" variant="primary" className="rounded-circle" type="button" onClick={createSummary}> <ListUl /> </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltipTranslate}>
            <Button aria-label="Översätt mejlet" variant="primary" className="rounded-circle" type="button" onClick={translateText}> <Translate /> </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltipClear}>
            <Button aria-label="Rensa skärmen" variant="outline-primary" className="rounded-circle" type="submit"> <XLg /> </Button>
          </OverlayTrigger>
        </Form.Group>
      </Form>
      <Form className="Output-form">
        <Form.Group className="mb-3" controlId="outputForm">
          <h3>Förslag</h3>
          <Form.Control as="textarea"
            value={outputText}
            onChange={(e) => {
              setOutputText(e.target.value);
            }}
            rows={rowsTextArea}
            placeholder="Här kommer förslaget." />
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltipPlay}>
            <Button aria-label="Läs upp förslaget" variant="primary" className="rounded-circle" type="button" onClick={() => isPlaying ? stopReadAloud() : readAloud(outputText)}> <IconPlayStop play={!isPlaying} /> </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltipCopy}>
            <Button aria-label="Kopiera texten till urklipp" variant="primary" className="rounded-circle" type="button" onClick={() => copyToClipboard(outputText)}> <IconCopyState state={copyState} /> </Button>
          </OverlayTrigger>
        </Form.Group>
      </Form>
    </div>
  );
}

export default App;
